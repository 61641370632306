import { sortBy, isTruthy } from '@st/utils'
import type { TSportEvent } from 'markets-store/types'
import { useSportEventsByEntityId } from '@st/nuxt-markets-store/composables/useSportEventsByEntityId'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useEventOfTheDay, useSecondEvents } from '../../composables'

interface ExtendedSportEvent extends TSportEvent {
  isMainEvent: boolean
}
interface ReturnTypes {
  allEventsCount: ComputedRef<number>
  eventsForRender: ComputedRef<ExtendedSportEvent[]>
  tabs: ComputedRef<
    {
      id: string | number
      title: string
      icon: IconName
    }[]
  >
  activeTab: Ref<string | number>
  currentEventsLink: ComputedRef<string>
  currentEventsCount: ComputedRef<number>
}

export function useMainSportSection(): ReturnTypes {
  const { t } = useI18n()

  const events = useSportEvents()
  const allEventsCount = computed(() => events.value.length)
  const { sportTabs } = useSportTabs(events)

  const { eventOfTheDay } = useEventOfTheDay()
  const { secondEvents } = useSecondEvents()
  const extendedEventOfTheDay = computed<ExtendedSportEvent | undefined>(() => {
    if (eventOfTheDay.value) {
      return {
        ...eventOfTheDay.value,
        isMainEvent: true,
      }
    }
    return undefined
  })
  const mainEvents = computed(() =>
    [extendedEventOfTheDay.value, ...secondEvents.value].filter(isTruthy),
  )
  const tabs = computed(() => {
    const mainEventsTab = {
      id: 'main',
      title: t('sportHomePage.matchesOfTheDay'),
      icon: 'all-sports' as const,
      link: '/sport',
    }

    const preparedSportTabs = sportTabs.value.map(
      ({ id, title, icon, link }) => ({
        id,
        title,
        icon,
        link,
      }),
    )
    return [
      ...(mainEvents.value.length ? [mainEventsTab] : []),
      ...preparedSportTabs,
    ]
  })
  const activeTab = ref(tabs.value[0]?.id)
  const currentEventsLink = computed(
    () => tabs.value.find(({ id }) => id === activeTab.value)?.link ?? '/sport',
  )

  const eventsByEntity = useSportEventsByEntityId(activeTab)

  const currentEventsCount = computed(() =>
    activeTab.value === 'main'
      ? mainEvents.value.length
      : eventsByEntity.value.length,
  )

  const EVENTS_FOR_RENDER_COUNT = 6
  const eventsForRender = computed(() => {
    if (activeTab.value === 'main') {
      return mainEvents.value as ExtendedSportEvent[]
    }
    const sortedEvents = sortBy(eventsByEntity.value, (event) => [
      event.scheduled,
      event.weight,
    ])

    return sortedEvents.slice(
      0,
      EVENTS_FOR_RENDER_COUNT,
    ) as ExtendedSportEvent[]
  })

  watch(
    () => mainEvents.value.length,
    (newValue, oldValue) => {
      if (newValue > 0 && oldValue === 0) {
        activeTab.value = tabs.value[0]?.id
      }
    },
  )

  return {
    allEventsCount,
    eventsForRender,
    tabs,
    activeTab,
    currentEventsLink,
    currentEventsCount,
  }
}
