import type { TSportEvent } from 'markets-store'
import { buildEventsByEntityMap } from 'markets-store/helpers/filterCustomMenu'
import { useMarketsStore } from './useMarketsStore'

export function useSportEventsByEntityId(
  entityId: Ref<string> | string,
  events?: Ref<TSportEvent[]> | TSportEvent[],
): ComputedRef<TSportEvent[]> {
  const allEvents = useSportEvents()
  const customMenu = useCustomMenu()

  const eventsForMap = computed(() => unref(events) ?? allEvents.value)
  const eventsIdsByEntity = computed(() =>
    buildEventsByEntityMap(customMenu.value, eventsForMap.value),
  )

  const { getByIds } = useMarketsStore().sportEvents

  return computed(() => {
    const ids = Array.from(eventsIdsByEntity.value[unref(entityId)] ?? [])
    return getByIds(ids) as TSportEvent[]
  })
}
