import type { RequestBody } from '@st/openapi-tools'
import type { paths } from '@st/api/paths'

export type GameFindParams = Exclude<
  RequestBody<paths, '/casino/game/find', 'post'>,
  undefined
>['params']

export function useGamesCounter(
  filterBy?: Ref<GameFindParams> | GameFindParams,
): Ref<number> {
  const { isMobile } = usePlatform()

  const { data: counter } = useStFetch('/casino/game/find', {
    method: 'post',
    server: false,
    lazy: true,
    body: computed(() => ({
      params: {
        ...(isMobile.value ? { forMobile: true } : { forDesktop: true }),
        ...toValue(filterBy ?? {}),
      },
      pagination: {
        page: 0,
        perPage: 1,
        orderBy: [
          {
            sortOrder: 'DESC' as const,
            fieldName: 'weightInCategory' as const,
          },
        ],
      },
    })),
    transform: (input) => input?.paging?.total ?? 0,
    default: () => 0,
  })

  return counter
}
